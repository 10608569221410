var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{staticClass:"overflow-y-auto user-list",attrs:{"dense":"","two-line":""}},_vm._l((_vm.GET_USERS),function(user,id){return _c('v-list-item',{key:id},[_c('v-list-item-avatar',[_c('img',{staticClass:"avatar-img",class:_vm.getSyncBorderClass(user),attrs:{"src":user.thumb}}),(user.state !== 'playing')?_c('v-icon',{staticClass:"avatar-icon"},[_vm._v(" "+_vm._s(_vm.stateIcons[user.state])+" ")]):_vm._e()],1),_c('v-list-item-content',[_c('v-tooltip',{attrs:{"bottom":"","multi-line":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-list-item-title',[_vm._v(" "+_vm._s(user.username)+" "),(id === _vm.GET_SOCKET_ID)?_c('span',{staticClass:"text--secondary"},[_vm._v(" (you) ")]):_vm._e()]),_c('v-list-item-subtitle',{staticClass:"text--secondary text-tiny"},[_vm._v(" "+_vm._s(_vm.getTitle(user.media))+" ")])],1)]}}],null,true)},[_vm._v(" Watching on "+_vm._s(user.playerProduct || "Unknown Plex Client")+" "),(user.media && _vm.GET_PLEX_SERVER(user.media.machineIdentifier))?_c('span',[_c('br'),_vm._v(" via "+_vm._s(_vm.GET_PLEX_SERVER(user.media.machineIdentifier).name)+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex justify-space-between text-tiny"},[_c('div',[_vm._v(" "+_vm._s(_vm.getTimeFromMs(_vm.getAdjustedTime(user)))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.getTimeFromMs(user.duration))+" ")])]),_c('v-progress-linear',{staticClass:"pt-content-progress",attrs:{"height":2,"value":_vm.percent(user)}})],1),_c('v-list-item-action',[(id === _vm.GET_HOST_ID || _vm.AM_I_HOST)?_c('v-tooltip',{attrs:{"bottom":"","multi-line":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){_vm.AM_I_HOST && id !== _vm.GET_HOST_ID ? _vm.TRANSFER_HOST(id) : null}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getHostIconName(id === _vm.GET_HOST_ID))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getHostActionText(id === _vm.GET_HOST_ID)))])]):_vm._e(),(id !== _vm.GET_HOST_ID && _vm.AM_I_HOST)?_c('v-tooltip',{attrs:{"bottom":"","multi-line":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.KICK_USER(id)}}},'v-icon',attrs,false),on),[_vm._v(" clear ")])]}}],null,true)},[_c('span',[_vm._v("Kick")])]):_vm._e()],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }